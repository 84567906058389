import unhead_6LP9AyC3RU from "/var/www/html/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_03ffb0428e3b1dd747d147e9bdae7c13/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kfm67HBGKh from "/var/www/html/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_03ffb0428e3b1dd747d147e9bdae7c13/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Uh8M2WjX4K from "/var/www/html/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.30.1_vite@6.0.11_@types+node@22.10.7_ji_702e798cff58b528998d32fc5d9abf0d/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import boot_OP3cd6SIk7 from "/var/www/html/plugins/boot.ts";
import window_mY4jZ4wBC5 from "/var/www/html/plugins/window.ts";
import payload_client_kMc4QAi6ef from "/var/www/html/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_03ffb0428e3b1dd747d147e9bdae7c13/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dTDCvyxuUA from "/var/www/html/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_03ffb0428e3b1dd747d147e9bdae7c13/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_QhRzJGjBVQ from "/var/www/html/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_03ffb0428e3b1dd747d147e9bdae7c13/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_LgChBCVnYA from "/var/www/html/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_03ffb0428e3b1dd747d147e9bdae7c13/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_W5kRox2PQf from "/var/www/html/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.0.11_@types+node@22.10.7_jiti@2.4_f1dad1fec69b691a6680600206f24b24/node_modules/@nuxt/icon/dist/runtime/plugin.js";
export default [
  unhead_6LP9AyC3RU,
  router_kfm67HBGKh,
  _0_siteConfig_Uh8M2WjX4K,
  boot_OP3cd6SIk7,
  window_mY4jZ4wBC5,
  payload_client_kMc4QAi6ef,
  navigation_repaint_client_dTDCvyxuUA,
  chunk_reload_client_QhRzJGjBVQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LgChBCVnYA,
  plugin_W5kRox2PQf
]